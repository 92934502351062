<template>
    <div class="container">
        <router-link class="container-function" :to="{ name: 'pedidos' }">
            <div class="function">
                <img src="../../assets/img/money-white.svg" alt="inclusão" />
                <h3>Solicitação de rebaixa</h3>
            </div>
        </router-link>
        <!-- <div class="container-function" @click="mensagemAviso.isWarning = true">
            <div class="function">
                <img src="../../assets/img/whatsapp_white.svg" alt="inclusão" />
                <h3>Suporte</h3>
            </div>
        </div> -->

        <transition name="fade">
            <div v-if="mensagemAviso.isWarning" class="popUp">
                <div class="tela-mensagem">
                    <div class="cancel-msg">
                        {{ mensagemAviso.top }}
                        <div class="close-cancel" @click="mensagemAviso.isWarning = false">
                            <img src="../../assets/img/close-black.svg" alt="fechar a modal" />
                        </div>
                    </div>
                    <h3>{{ mensagemAviso.body }}</h3>
                    <div class="cancel-btn">
                        <button @click="mensagemAviso.isWarning = false" type="button">
                            {{ mensagemAviso.cancel }}
                        </button>
                        <button @click="avisoFunction()" type="button">
                            {{ mensagemAviso.accept }}
                        </button>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    data() {
        return {
            mensagemAviso: {
                top: 'Atenção!',
                body: 'Está precisando de suporte na execução dos pedidos?',
                cancel: 'Cancelar',
                accept: 'Prosseguir',
                isWarning: false,
            },
        };
    },

    methods: {
        avisoFunction() {
            window.open(
                'https://api.whatsapp.com/send?phone=5544997525279&text=Ol%C3%A1%2C%20preciso%20de%20ajuda%20com%20a%20aprova%C3%A7%C3%A3o%20de%20pre%C3%A7os',
                '_blank'
            );
        },
    },
};
</script>

<style scoped>
.container {
    margin: 66px 20px 57px 20px;
    display: grid;
    place-items: center;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
    /* row-gap: 1.5rem; */
    min-height: 20rem;
}

.function {
    display: flex;
    position: relative;
    flex-direction: column;
    place-content: center;
    align-items: center;
    text-align: center;
    text-decoration: none;
    color: #fff;
    border: 1px solid var(--azul-escuro);
    border-radius: 10px;
    background-color: var(--verde);
    padding: 15px;
    min-height: 5rem;
    max-height: 5rem;
    min-width: 6rem;
    max-width: 6rem;
    gap: 10px;
    width: fit-content;
    box-shadow: -6px 7px 0px 0px var(--azul-escuro);
}

.function:hover {
    background-color: var(--azul-escuro);
    border: 1px solid var(--verde);
    box-shadow: -6px 7px 0px 0px var(--verde);
    position: relative;
    top: 5px;
}

.function h3 {
    font-size: 0.8rem;
}

.function-number {
    position: absolute;
    display: flex;

    top: -18px;
    right: -18px;
}

.function-number h3 {
    width: 2rem;
    height: 2rem;
    color: var(--vermelho);
    font-weight: bold;
    display: flex;
    place-content: center;
    place-items: center;
    font-size: 0.8rem;
    border: 1px solid var(--azul-escuro);
    border-radius: 100%;
    padding: 10px;
    background-color: var(--cinza-claro);
}

/* is warning */

.popUp {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 9999;
}

.tela-mensagem {
    position: absolute;
    left: 6%;
    top: 15%;
    right: 6%;
    margin: 18px;
    background-color: #f2f2f2;
    border-radius: 5px;
    border: 1px solid rgb(197, 197, 197);
    padding-bottom: 10px;
}

.tela-mensagem h3 {
    padding: 5px 15px;
    font-size: 14px;
}

.cancel-msg {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    font-size: 25px;
    font-weight: bold;
}

.close-cancel {
    display: flex;
    background-color: #d3d3d3;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.2s;
}

.close-cancel:hover {
    border-radius: 50%;
    transition: all 0.2s;
    background-color: #f4f4f4;
    border: 1px solid #d3d3d3;
}

.cancel-btn {
    margin-top: 8%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.cancel-btn button {
    padding: 5px 10px;
    font-weight: bold;
    background-color: #f4f4f4;
    border: 1px solid #cdcdcd;
    border-radius: 5px;
    cursor: pointer;
    font-size: 15px;
}

.cancel-btn button:hover {
    position: relative;
    top: 2px;
    background-color: var(--verde);
    color: #fff;
}
/* is warning */

@media (max-width: 289px) {
    .container {
        column-gap: 0;
    }
    .function {
        min-height: 5rem;
        max-height: 5rem;
        min-width: 5rem;
        max-width: 5rem;
    }

    .function h3 {
        font-size: 0.8rem;
    }
}
</style>
